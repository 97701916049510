<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" v-if="dialogStatus == 'share' || dialogStatus == 'shareList' ">
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="接收人" prop="receivePerson">
                        <el-select v-model="ruleForm.receivePerson" filterable value-key="id" placeholder="请选择"
                            style="width: 310px;">
                            <el-option v-for="item in personTypeOptions" :key="item.value.id" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item align="right" style="margin-top: 58px;">
                <el-button type="plain" @click="clickStep('close')">取消</el-button>
                <el-button type="primary" @click="clickStep('save')">提交</el-button>
            </el-form-item>
        </el-form>
        <div v-else>
            <el-row :gutter="48">
                <el-col :span="12">
                    <div class="dialogtext">
                        <p class="title">客户企业名称</p>
                        <p class="text">aaa</p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="dialogtext">
                        <p class="title">客户编号</p>
                        <p class="text">aaa</p>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <div class="dialogtext">
                        <p class="title">租户类型</p>
                        <p class="text">aaa</p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="dialogtext">
                        <p class="title">企业类型</p>
                        <p class="text">aaa</p>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <div class="dialogtext">
                        <p class="title">联系人</p>
                        <p class="text">aaa</p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="dialogtext">
                        <p class="title">联系电话</p>
                        <p class="text">aaa</p>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <div class="dialogtext">
                        <p class="title">邮箱</p>
                        <p class="text">aaa</p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="dialogtext">
                        <p class="title">地址</p>
                        <p class="text">aaa</p>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <div class="dialogtext">
                        <p class="title">客户来源</p>
                        <p class="text">aaa</p>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import {
    getSalePersonList,
    moveCustomers,
    transferCustomers,
    shareCustomerList
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
export default {
    props: {
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
        ids: {
            required: false,
        },
    },
    data() {
        return {
            ruleForm: {
                receivePerson: ''
            },
            rules: {
                receivePerson: [
                    { required: true, message: '必填项', trigger: 'change' }
                ]
            },
            personTypeOptions: [],
        }
    },
    methods: {
        clickStep(type) {
            if (type == 'close') {
                this.$emit("close", false);
            } else if (type == 'save') {
                console.log(this.currentState, '状态');
                console.log(this.id, 'id');
                this.$refs["ruleForm"].validate((valid) => {
                    if (valid) {
                        if (this.dialogStatus == 'share') {
                            transferCustomers({
                                id: this.id,
                                status: 1,
                                employeeName: this.ruleForm.receivePerson.employeeName
                            }).then(res => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        } else if( this.dialogStatus == 'shareList' ) {
                            shareCustomerList({
                                ids: this.ids,
                                employeeName: this.ruleForm.receivePerson.employeeName
                            }).then(res => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        }

                    }
                })
            }
        },
        getpersonList() {
            this.personTypeOptions = []
            getSalePersonList().then(res => {
                res.data.map((item) => {
                    this.personTypeOptions.push({
                        value: item,
                        label: item.employeeName,
                    })
                })
            })
        },
    },
    watch: {
        id: {
            handler(id) {
                if (!id) return;
                // this.getMeetingDetail(id);
            },
            immediate: true,
        },
        ids: {
            handler(ids) {
                if (!ids) return;
            },
            immediate: true,
        },
        dialogStatus: {
            handler(val) {
                this.currentState = val;
                if (val == 'share' || val == 'shareList') {
                    this.getpersonList()
                }
            },
            immediate: true,
        },
    }
}
</script>
<style lang="less" scoped>
.dialogtext {
    .title {
        font-family: Noto Sans SC, Noto Sans SC;
        color: #999;
        font-size: 12px;
        font-weight: 300;
    }

    .text {
        font-family: Noto Sans SC, Noto Sans SC;
        color: #2F3941;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}
</style>
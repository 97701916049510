var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.dialogStatus == "share" || _vm.dialogStatus == "shareList"
        ? _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "接收人", prop: "receivePerson" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: {
                                filterable: "",
                                "value-key": "id",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.ruleForm.receivePerson,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "receivePerson", $$v)
                                },
                                expression: "ruleForm.receivePerson",
                              },
                            },
                            _vm._l(_vm.personTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value.id,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "58px" },
                  attrs: { align: "right" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "plain" },
                      on: {
                        click: function ($event) {
                          return _vm.clickStep("close")
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickStep("save")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dialogtext" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v("客户企业名称"),
                      ]),
                      _c("p", { staticClass: "text" }, [_vm._v("aaa")]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dialogtext" }, [
                      _c("p", { staticClass: "title" }, [_vm._v("客户编号")]),
                      _c("p", { staticClass: "text" }, [_vm._v("aaa")]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dialogtext" }, [
                      _c("p", { staticClass: "title" }, [_vm._v("租户类型")]),
                      _c("p", { staticClass: "text" }, [_vm._v("aaa")]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dialogtext" }, [
                      _c("p", { staticClass: "title" }, [_vm._v("企业类型")]),
                      _c("p", { staticClass: "text" }, [_vm._v("aaa")]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dialogtext" }, [
                      _c("p", { staticClass: "title" }, [_vm._v("联系人")]),
                      _c("p", { staticClass: "text" }, [_vm._v("aaa")]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dialogtext" }, [
                      _c("p", { staticClass: "title" }, [_vm._v("联系电话")]),
                      _c("p", { staticClass: "text" }, [_vm._v("aaa")]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dialogtext" }, [
                      _c("p", { staticClass: "title" }, [_vm._v("邮箱")]),
                      _c("p", { staticClass: "text" }, [_vm._v("aaa")]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dialogtext" }, [
                      _c("p", { staticClass: "title" }, [_vm._v("地址")]),
                      _c("p", { staticClass: "text" }, [_vm._v("aaa")]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dialogtext" }, [
                      _c("p", { staticClass: "title" }, [_vm._v("客户来源")]),
                      _c("p", { staticClass: "text" }, [_vm._v("aaa")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="app-container">
        <div class="filter-line">
            <span>
                <el-tooltip  :disabled="selectionids.length > 0"  :content="$t('commons.multipleSelectionOne')" placement="top">
                    <el-button :class="selectionids.length === 0 && 'not-allowed-button'"
                    @click="shareClick">批量分配</el-button>
                </el-tooltip>
                <el-button icon="el-icon-refresh-right" @click="getTableList()">{{ this.$t("commons.refresh")
                    }}</el-button>
                <el-button icon="el-icon-download" @click="exportHandler" v-if="havePermissions.includes(
                    'gcrm$Service$tenantCrmManagement$exportCustomerInfo'
                )">{{ this.$t("commons.export") }}</el-button>
                <el-button icon="el-icon-upload2" @click="uploadModel = true">{{ this.$t("commons.import")
                    }}</el-button>
                <el-button icon="el-icon-upload" @click="downHighseasTemplate">导入模板</el-button>
            </span>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" />
        </div>
        <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" width="728px">
            <template slot="title">
                <span>{{ highSeasList.dialogTitle }}</span>
            </template>
            <seasDialog v-if="dialogVisible" @close="handleClose" :id="highSeasList.id"
                :dialogStatus="highSeasList.dialogStatus" :ids="highSeasList.ids">
            </seasDialog>
        </el-dialog>
        <!--导入文件-->
        <el-dialog :visible.sync="uploadModel" width="40%">
            <template slot="title">
                <title-icon />{{ $t("commons.uploadFile") }}
            </template>
            <div slot="footer">
                <upload :ulType="uploadType" :excelType="excelType" :rootPath="rootPath" @success="uploadSuccess"
                    @fail="uploadFail"></upload>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import seasDialog from "./seasDialog.vue"
import {
    downHighseasTemplate
} from "@/api/business/base/tenant/materiel";
import {
    publicCustomerList,
    deleteCustomers,
    publicCustomerListExport
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
import { envInfo } from "@/constants/envInfo";
import Upload from "@/components/Upload";
import { mapGetters } from "vuex";
export default {
    components: {
        finalTable,
        seasDialog,
        Upload
    },
    data() {
        return {
            uploadModel: false,
            uploadType: "ExcelImport",
            rootPath: envInfo.bgApp.personnelmanagePath,
            excelType: "ruge.customer",
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                selection: {
                    need: true,
                    prop: "productId",
                    width: "18",
                },
                header: [
                    { prop: "creationDate", label: "创建时间", width: "" },
                    { prop: "createdByStr", label: "创建人", width: "" },
                    { prop: "enterpriseName", label: "客户企业名称", width: "" },
                    { prop: "tenantType", label: "租户类型", width: "" },
                    { prop: "customerCode", label: "客户编号", width: "" },
                    { prop: "contacts", label: "联系人", width: "" },
                    { prop: "contactNumber", label: "联系电话", width: "" },
                    { prop: "operation", label: "操作", width: "180" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {},
                // 表格内容配置
                detailConfig: {
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "share",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "分配",
                                showProp: "statuskey",
                                showValue: true,
                            },
                            {
                                actionType: "iconClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "详情",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "delete",
                                fontSize: "14px",
                                color: "#E8331C",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "删除",
                                showProp: "statusKey",
                                showValue: true,
                            },
                        ],
                    }
                },
                // 高级搜索配置
                advanceFilterConfig: {},
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            dialogVisible: false,
            highSeasList: {
                dialogStatus: 'share',
                dialogTitle: "分配客户",
            },
            personnelObj: {
                tableLoading: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    type: 2
                }
            },
            selectionids: []
        }
    },
    created() {
        this.getTableList()
    },
    computed: {
        ...mapGetters(["havePermissions"]),
    },
    methods: {
        getTableList() {
            console.log('111');
            this.personnelObj.tableLoading = true;
            const params = { ...this.personnelObj.listQuery };
            const hasDeletePermision = this.havePermissions.includes('gcrm$Service$tenantCrmManagement$deleteCustomerInfo');
            const hasSharePermision = this.havePermissions.includes('gcrm$Service$tenantCrmManagement$assignCustomer');
            this.setOperationWidth(hasDeletePermision)
            publicCustomerList(params).then(res => {
                res.data.rows.forEach((item) => {
                    this.$set(item, 'statusKey', hasDeletePermision)
                });
                res.data.rows.forEach((item) => {
                    this.$set(item, 'statuskey', hasSharePermision)
                });
                this.dataset.pageVO.total = res.data.total;
                this.dataset.tableData = res.data.rows;
            })
                .finally(() => {
                    this.personnelObj.tableLoading = false;
                });
        },
        setOperationWidth(hasDeletePermision) {
            this.dataset.header.forEach(item => {
                if (item.prop === 'operation') {
                    item.width = hasDeletePermision ? '180' : '80'
                }
            })
        },
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.personnelObj.listQuery = {
                    ...this.personnelObj.listQuery,
                    ...datas.params,
                };
                this.personnelObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                this.getTableList();
            } else if (datas.type === "paginationChange") {
                this.personnelObj.listQuery.current = datas.params.current.page;
                this.personnelObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "share":
                        this.dialogVisible = true;
                        this.highSeasList.id = datas.row.id;
                        this.highSeasList.dialogStatus = "share";
                        this.highSeasList.dialogTitle = "分配人员";

                        break;
                    case "view":
                        // this.dialogVisible = true;
                        // this.highSeasList.id = datas.row.id;
                        // this.highSeasList.dialogStatus = "view";
                        // this.highSeasList.dialogTitle = "详情";
                        this.singleViewHandler(datas.row)
                        break;
                    case "delete":
                        this.singleDeleteHandler(datas.row);
                }
            } else if (datas.type === "updateSelectionList") {
                // this.selectionids = datas.list.filter((item) => item);
                this.selectionids = datas.list.map((item) => item.id);
                console.log(datas.list, 'datas.list');
            }
        },
        shareClick() {
            if (this.selectionids.length === 0) {
                return
            }
            this.dialogVisible = true;
            this.highSeasList.ids = this.selectionids;
            this.highSeasList.dialogStatus = "shareList";
            this.highSeasList.dialogTitle = "分配人员";

        },
        downHighseasTemplate() {
            downHighseasTemplate();
        },
        uploadSuccess() {
            this.uploadModel = false;
            // this.$message({
            //   type: "success",
            //   message: this.$t("message.uploadSuccess"),
            // });
        },
        uploadFail() {
            this.$message({ type: "error", message: this.$t("message.uploadFial") });
        },
        singleDeleteHandler(row) {
            this.$confirm(
                this.$t("message.deleteConfirm"),
                this.$t("commons.warning"),
                {
                    confirmButtonText: this.$t("commons.confirm"),
                    cancelButtonText: this.$t("commons.cancel"),
                    type: "warning",
                }
            ).then(() => {
                console.log(row, 'row');
                deleteCustomers({
                    id: row.id
                }).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getTableList()
                    } else if (res.code == 424) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    } else if (res.code == 500) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        handleClose(freshFlag) {
            this.dialogVisible = false
            freshFlag && this.getTableList();
        },
        singleViewHandler(row) {
            this.$router.push({
                path: "/gsPark/customerRelaManagement/highseas/highseasView",
                query: { id: row.id },
            });
        },
        // 导出
        exportHandler() {
            publicCustomerListExport(this.personnelObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "公海池列表",
                    taskStatus: 0,
                    rootPath: "gcrm",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
    },
    watch: {
        personnelObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        }
    }
}
</script>
<style lang="less" scoped>
.filter-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.not-allowed-button {
    cursor: not-allowed;
    background-color: #F8F8F8;
    color: #909399;
}
</style>